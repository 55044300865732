<template>
  <div class="relative">
    <slot />
    <v-overlay
      :model-value="loading"
      class="flex-center"
      :class="{ 'rounded-md overflow-hidden': rounded }"
      :contained="!fullscreen"
    >
      <v-progress-circular width="2" :size="loaderSize" indeterminate />
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "OverlayLoader",
  props: {
    loading: { type: Boolean, default: false },
    loaderSize: { type: [String, Number], default: 40 },
    rounded: { type: Boolean, default: false },
    fullscreen: { type: Boolean, default: false },
  },
});
</script>
